import React, { useState, useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Accordion.module.scss';
import Icon from '../Icon/Icon';

const Accordion = ({ title, content }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const id = useId();
    const headerId = useId();

    const classes = classNames(styles['Accordion'], {
        [styles['Accordion--Expanded']]: isExpanded,
    });

    const handleClick = () => {
        setIsExpanded(!isExpanded);

        if (typeof window.dataLayer === 'undefined') return;
        window.dataLayer.push({
            event: 'faq_interaction',
            faq_question: title,
            faq_interaction_type: isExpanded ? 'shrink' : 'expand',
        });
    };

    return (
        <div className={classes}>
            <button
                className={styles['Accordion__Button']}
                onClick={() => handleClick()}
                aria-expanded={isExpanded}
                aria-controls={id}
                id={headerId + '-header'}>
                {title}
                <Icon
                    icon={isExpanded ? 'minus' : 'plus'}
                    modifiers={['accordion']}
                    dimensions={{ width: '35px', height: '35px' }}
                />
            </button>
            <div
                className={styles['Accordion__Content']}
                aria-labelledby={headerId + '-header'}
                aria-hidden={!isExpanded}
                id={id}>
                <div className={styles['Accordion__Inner']}>
                    <div
                        className={styles['Accordion__RichText']}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </div>
            </div>
        </div>
    );
};

Accordion.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default Accordion;
