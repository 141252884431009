// import PropTypes from 'prop-types';
import Accordion from '../Accordion/Accordion';
import styles from './FaqList.module.scss';

const FaqList = ({ title = '', items = [] }) => {
    if (!items.length) {
        return;
    }
    
    return (
        <div className={styles['FaqList']}>
            <h2 className={styles['FaqList__Title']}>{title}</h2>
            {items.map((item, index) => (
                <Accordion key={index} {...item} />
            ))}
        </div>
    );
};

FaqList.propTypes = {};

export default FaqList;
